.button-pad {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: 30px;
	padding: 5px;
}

.item-pad {
	flex: 1 1 auto;
	margin: 5px;
}
